<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <script type="application/javascript" src="https://js.stripe.com/v3/"></script>
    <v-card>
      <div v-if="user">
        <v-card v-if="status && status.status === 'OK'" class="elevation-12 pa-4 text-center">
          <v-card-title class="headline d-block" style="">Link created</v-card-title>
          <v-card-text class="">
            <v-alert type="success">Stripe linked successfully.</v-alert>
          </v-card-text>
        </v-card>
        <v-card v-else-if="isLoading" class="elevation-12 pa-4 text-center">
          <v-card-title class="headline d-block" style="">Preparing link</v-card-title>
          <v-card-text class="">
            <p>One second, we're preparing the account link...</p>
            <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="subtitle">Link Stripe Account</v-card-title>
          <v-card-text>      
            <p>You can connect an existing Stripe account to receive merchant payments directly on your account.</p>
            <v-btn x-large color="primary" @click="link">Link Accounts</v-btn>
            <v-alert v-if="status && status.status === 'ERROR'" type="warning">{{status.msg}}</v-alert>
            <v-alert v-if="status && status.status === 'INFO'" type="info">{{status.msg}}</v-alert>
          </v-card-text> 
        </v-card>  
      </div>    
      <div v-else>
        <v-alert type="info" tile>You'll need to login first.</v-alert>
        <v-card-text>
          <p>Please Login or Register to continue.</p>
          <v-btn color="accent" class="mr-4" :to="{ name: 'register', query: {referral: this.$route.query.referral, redirect:'checkout-connect' }}">Register</v-btn>
          <v-btn outlined color="accent" :to="{name: 'login', query: { referral: this.$route.query.referral, redirect:'checkout-connect' } }">Login</v-btn>
        </v-card-text>
      </div>  
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import checkoutService from "@/services/checkoutService";

export default {
  name: "CheckoutConnect",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Checkout', disabled: true },
        { text: 'Cart', disabled: true },
      ],
      cart: null,
      isLoading: false,
      valid: false,
      error: null,
      status: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.getStatus();
        console.log(this.cart);
        //this.stripeSessionId = response.data.id;
      }
    },
    async link() {
      this.isLoading = true;
      const response = (await checkoutService.startConnectFlow()).data;
      console.log('Strip connect result', response);
      this.valid = true;
      this.isLoading = false;
      window.location.replace(response.url);
    },
    async getStatus() {
      this.status = (await checkoutService.getConnectStatus()).data;
      console.log('Strip connect status', this.status);
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

